import React, { useEffect, useState } from "react";
import {
  getAllPeminjaman,
  createPeminjaman,
  updatePeminjaman,
  deletePeminjaman,
} from "../services/peminjamanService";
import { getAllBarang } from "../services/barangService";
import PeminjamanList from "../components/PeminjamanList";
import BarangDropdown from "../components/BarangDropdown";
import Notification from "../components/Notification";
import {
  PlusIcon,
  PencilSquareIcon,
  ArrowUturnLeftIcon,
} from "@heroicons/react/24/solid";

// Format data barang sesuai dengan struktur yang diharapkan
const formatBarangData = (barangs) => {
  return barangs.map((b) => ({
    barang: b.id,
    jumlah: b.PeminjamanBarang.jumlah || 1,
  }));
};

const PeminjamanPage = () => {
  const [peminjaman, setPeminjaman] = useState([]);
  const [barang, setBarang] = useState([]);
  const [editingPeminjaman, setEditingPeminjaman] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [form, setForm] = useState({
    name: "",
    instansi: "",
    nosurat: "",
    tanggungjawab: "",
    tujuan: "",
    barang: [],
  });

  // Fetch peminjaman data
  const fetchPeminjaman = async () => {
    try {
      const data = await getAllPeminjaman();
      setPeminjaman(data);
    } catch (error) {
      console.error("Error fetching peminjaman:", error);
      setError("Error fetching peminjaman");
    }
  };

  useEffect(() => {
    fetchPeminjaman();
  }, []);

  // Fetch barang data
  useEffect(() => {
    const fetchBarang = async () => {
      try {
        const data = await getAllBarang();
        setBarang(data);
      } catch (error) {
        console.error("Error fetching barang:", error);
        setError("Error fetching barang");
      }
    };
    fetchBarang();
  }, []);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  // Handle barang dropdown change
  const handleBarangChange = (selectedBarang) => {
    const formattedBarang = selectedBarang.map((item) => ({
      barang: item.barang,
      jumlah: Number(item.jumlah),
    }));
    setForm((prevForm) => ({ ...prevForm, barang: formattedBarang }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingPeminjaman) {
        await updatePeminjaman(editingPeminjaman.id, form);
        setSuccess("Updated successfully!");
      } else {
        await createPeminjaman(form);
        setSuccess("Created successfully!");
      }
      resetForm();
      setEditingPeminjaman(null);
      fetchPeminjaman(); // Refresh the data
    } catch (error) {
      console.error("Error saving peminjaman:", error);
      setError("Error saving peminjaman");
    }
  };

  const resetForm = () => {
    setForm({
      name: "",
      instansi: "",
      nosurat: "",
      tanggungjawab: "",
      tujuan: "",
      barang: [],
    });
    setEditingPeminjaman(null);
    setIsAdding(false);
  };

  // Set form for editing
  const handleEdit = (peminjaman) => {
    setForm({
      name: peminjaman.name,
      instansi: peminjaman.instansi,
      nosurat: peminjaman.nosurat,
      tanggungjawab: peminjaman.tanggungjawab,
      tujuan: peminjaman.tujuan,
      barang: formatBarangData(peminjaman.Barangs),
    });
    setEditingPeminjaman(peminjaman);
    setError("");
    setSuccess("");
  };

  // Handle delete
  const handleDelete = async (id) => {
    try {
      await deletePeminjaman(id);
      setPeminjaman(peminjaman.filter((p) => p.id !== id));
    } catch (error) {
      console.error("Error deleting peminjaman:", error);
    }
  };

  // Handle add button click
  const handleAdd = () => {
    resetForm();
    setEditingPeminjaman(null);
    setIsAdding(true); // Set add mode
    setError("");
    setSuccess("");
  };

  // Handle cancel button click
  const handleCancel = () => {
    resetForm();
  };

  const shouldShowPeminjamanList = !isAdding && !editingPeminjaman;

  return (
    <div>
      <h1 className="text-xl font-bold mb-4 mt-8">
        {editingPeminjaman ? "Update" : "Add"} Peminjaman
      </h1>
      {!isAdding && !editingPeminjaman && (
        <button onClick={handleAdd} className="btn btn-md btn-accent mb-4">
          <PlusIcon className="h-5 w-5" /> Add
        </button>
      )}

      <Notification message={error} type="error" />
      <Notification message={success} type="success" />

      {(editingPeminjaman || isAdding) && (
        <form onSubmit={handleSubmit} className="form-control gap-2">
          <input
            type="text"
            name="name"
            value={form.name}
            className="input input-bordered"
            onChange={handleInputChange}
            placeholder="Name"
            required
          />
          <input
            type="text"
            name="instansi"
            value={form.instansi}
            className="input input-bordered"
            onChange={handleInputChange}
            placeholder="Instansi"
            required
          />
          <input
            type="text"
            name="tanggungjawab"
            value={form.tanggungjawab}
            className="input input-bordered"
            onChange={handleInputChange}
            placeholder="Penanggung Jawab"
            required
          />
          <input
            type="text"
            name="nosurat"
            value={form.nosurat}
            className="input input-bordered"
            onChange={handleInputChange}
            placeholder="No Surat"
            required
          />
          <input
            type="text"
            name="tujuan"
            value={form.tujuan}
            className="input input-bordered"
            onChange={handleInputChange}
            placeholder="Tujuan"
            required
          />
          <BarangDropdown
            barang={barang}
            selectedBarang={form.barang}
            onChange={handleBarangChange}
          />
          <button className="btn btn-accent" type="submit">
            <PencilSquareIcon className="h-4 w-4 mr-2" />
            {editingPeminjaman ? "Update" : "Save"}
          </button>
          <button type="button" className="btn" onClick={handleCancel}>
            <ArrowUturnLeftIcon className="h-4 w-4 mr-2" />
            Cancel
          </button>
        </form>
      )}

      {shouldShowPeminjamanList && (
        <PeminjamanList
          peminjaman={peminjaman}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default PeminjamanPage;
