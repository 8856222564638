// src/components/BarangList.js
import React from "react";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";

const BarangList = ({ barang, onEdit, onDelete }) => {
  return (
    <div>
      {/* <h2>Daftar Barang</h2> */}
      <div className="overflow-x-auto">
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Code</th>
              <th>Category</th>
              <th>Brand</th>
              <th>Stock</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {barang.map((b) => (
              <tr key={b.id}>
                <td>{b.name}</td>
                <td>{b.code}</td>
                <td>{b.category}</td>
                <td>{b.brand}</td>
                <td>{b.stock}</td>
                <td className="flex gap-2">
                  <button className="btn btn-warning" onClick={() => onEdit(b)}>
                    <PencilSquareIcon className="h-4 w-4" />
                  </button>
                  <button
                    className="btn btn-error"
                    onClick={() => onDelete(b.id)}
                  >
                    <TrashIcon className="h-4 w-4" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BarangList;
