import React, { useEffect, useState } from "react";
import {
  getAllBarang,
  createBarang,
  updateBarang,
  deleteBarang,
} from "../services/barangService";
import BarangList from "../components/BarangList";
import {
  PlusIcon,
  PencilSquareIcon,
  ArrowUturnLeftIcon,
} from "@heroicons/react/24/solid";

const BarangPage = () => {
  const [barang, setBarang] = useState([]);
  const [editingBarang, setEditingBarang] = useState(null);
  const [isAdding, setIsAdding] = useState(false); // State to control add mode
  const [form, setForm] = useState({
    name: "",
    code: "",
    category: "",
    brand: "",
    stock: 0,
  });

  // Fetch barang data on component mount
  useEffect(() => {
    const fetchBarang = async () => {
      try {
        const data = await getAllBarang();
        setBarang(data);
      } catch (error) {
        console.error("Error fetching barang:", error);
      }
    };
    fetchBarang();
  }, []);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingBarang) {
        await updateBarang(editingBarang.id, form);
      } else {
        await createBarang(form);
      }
      // Reset form and fetch updated data
      resetForm();
      setEditingBarang(null);
      setIsAdding(false); // Reset add mode
      const data = await getAllBarang();
      setBarang(data);
    } catch (error) {
      console.error("Error saving barang:", error);
    }
  };

  // Set form for editing
  const handleEdit = (barang) => {
    setForm(barang);
    setEditingBarang(barang);
    setIsAdding(false); // Ensure not in add mode
  };

  // Handle delete
  const handleDelete = async (id) => {
    try {
      await deleteBarang(id);
      setBarang(barang.filter((b) => b.id !== id));
    } catch (error) {
      console.error("Error deleting barang:", error);
    }
  };

  const resetForm = () => {
    setForm({ name: "", code: "", category: "", brand: "", stock: 0 });
    setEditingBarang(null);
  };

  // Handle add button click
  const handleAdd = () => {
    resetForm();
    setEditingBarang(null);
    setIsAdding(true); // Set add mode
  };

  // Handle cancel button click
  const handleCancel = () => {
    resetForm();
    setEditingBarang(null);
    setIsAdding(false); // Reset add mode
  };

  // Determine whether to show the BarangList
  const shouldShowBarangList = !isAdding && !editingBarang;

  return (
    <div>
      <h1 className="text-xl font-bold mb-4 mt-8">
        {editingBarang ? "Update" : ""} Inventaris
      </h1>
      {/* Button to trigger add mode */}
      {!isAdding && !editingBarang && (
        <button onClick={handleAdd} className="btn btn-accent mb-4">
          <PlusIcon className="h-5 w-5" /> Add
        </button>
      )}

      {/* Conditionally render the form based on whether editingBarang or isAdding */}
      {(editingBarang || isAdding) && (
        <form onSubmit={handleSubmit} className="form-control gap-2">
          <input
            type="text"
            name="name"
            value={form.name}
            className="input input-bordered"
            onChange={handleInputChange}
            placeholder="Name"
            required
          />
          <input
            type="text"
            name="code"
            value={form.code}
            className="input input-bordered"
            onChange={handleInputChange}
            placeholder="Code"
            required
          />
          <input
            type="text"
            name="category"
            value={form.category}
            className="input input-bordered"
            onChange={handleInputChange}
            placeholder="Category"
            required
          />
          <input
            type="text"
            name="brand"
            value={form.brand}
            className="input input-bordered"
            onChange={handleInputChange}
            placeholder="Brand"
            required
          />
          <input
            type="number"
            name="stock"
            value={form.stock}
            className="input input-bordered"
            onChange={handleInputChange}
            placeholder="Stock"
            required
          />
          <button className="btn btn-accent" type="submit">
            <PencilSquareIcon className="w-4 h-4 mr-2" />

            {editingBarang ? "Update" : "Save"}
          </button>
          <button type="button" className="btn" onClick={handleCancel}>
            <ArrowUturnLeftIcon className="w-4 h-4 mr-2" />
            Cancel
          </button>
        </form>
      )}

      {/* Conditionally render BarangList based on the state */}
      {shouldShowBarangList && (
        <BarangList
          barang={barang}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default BarangPage;
