// src/components/PeminjamanList.js
import React from "react";
import {
  PencilSquareIcon,
  TrashIcon,
  ArrowUturnLeftIcon,
  EyeIcon,
} from "@heroicons/react/24/solid";
import { kembalikanPeminjaman } from "../services/peminjamanService";
import { Link } from "react-router-dom";

const PeminjamanList = ({ peminjaman, onEdit, onDelete, onKembalikan }) => {
  const handleKembalikan = async (id) => {
    try {
      await kembalikanPeminjaman(id);
      // Perbarui data di parent component atau refresh data sesuai kebutuhan
      if (onKembalikan) onKembalikan(); // Misalnya, memanggil callback dari parent component untuk refresh data
    } catch (error) {
      console.error("Error marking as returned:", error);
    }
  };

  return (
    <div>
      {/* <h2 className="text-xl">Daftar Peminjaman</h2> */}
      <div className="overflow-x-auto">
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Instansi</th>
              <th>No Surat</th>
              <th>Penanggung Jawab</th>
              <th>Tujuan</th>
              <th>Status</th>
              <th>Tgl Dikembalikan</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {peminjaman.map((p) => (
              <tr key={p.id}>
                <td>{p.name}</td>
                <td>{p.instansi}</td>
                <td>{p.nosurat}</td>
                <td>{p.tanggungjawab}</td>
                <td>{p.tujuan}</td>
                <td>{p.status}</td>
                <td>
                  {p.dikembalikan
                    ? new Date(p.dikembalikan).toLocaleDateString()
                    : "Belum Dikembalikan"}
                </td>
                <td className="flex gap-2">
                  <Link to={`/peminjaman/${p.id}`}>
                    <button className="btn btn-info">
                      <EyeIcon className="h-4 w-4" />
                    </button>
                  </Link>

                  <button className="btn btn-warning" onClick={() => onEdit(p)}>
                    <PencilSquareIcon className="h-4 w-4" />
                  </button>

                  <button
                    className="btn btn-error"
                    onClick={() => onDelete(p.id)}
                  >
                    <TrashIcon className="h-4 w-4" />
                  </button>

                  {p.status !== "Dikembalikan" && (
                    <button
                      className="btn btn-success"
                      onClick={() => handleKembalikan(p.id)}
                    >
                      <ArrowUturnLeftIcon className="h-4 w-4" />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PeminjamanList;
